<template>
  <div class="notices">
    <div class="title">消息</div>
    <div class="notices_bar">
      <Tabs :tabData="noticeBar" @handleClick="changeHandle" />
    </div>
    <div class="panel" v-if="list_data.lists.length">
      <div v-for="(item, index) in list_data.lists" :key="index" class="panelItem">
        <div class="item_head">
          <span>{{ item.title }}</span><span>{{ item.create_time }}</span>
        </div>
        <div class="item_desc">{{ item.content }}</div>
        <div class="item_detail" @click="goLink(messageType[item.source])">查看详情</div>
      </div>
    </div>
    <div class="panel" v-else>
      <el-empty :image-size="200"></el-empty>
    </div>
    <Pager @changePage="changePage" @getList="getData" :page_size="page.page_size" :total="list_data.total"></Pager>
  </div>
</template>
  
<script>
import Pager from '../../../components/pager/Pager.vue'
import Tabs from '../components/tabs'
import mixin from '../../../mixin'
export default {
  components: {
    Tabs,
    Pager
  },
  mixins: [mixin],
  data () {
    return {
      noticeBar: [
        {
          id: 1,
          text: '系统消息',
          value: 'system',
          active: true
        },
        {
          id: 2,
          text: '审核消息',
          value: 'check',
          active: false
        },
        {
          id: 3,
          text: '认证消息',
          value: 'auth',
          active: false

        }
      ],
      messageType: {
        0: '', //无
        1: '/personalCenter/realName', //1=个人认证
        2: '/personalCenter/enterpriseAuth', //2=企业认证
        3: '/workbench/myRecruitWork', //3=招工信息审核
        4: '/personalCenter/member', //vip到期
        5: '/personalCenter/member', //vip开通成功
        6: '/workbench/myBids', //招标信息审核
        7: '/workbench/myBids', //中标信息
        8: '/workbench/myRecruitWork', //招工审核
        9: '/workbench/mySubcontract', //劳务分包审核
        10: '/workbench/myPurchase', //招采审核
        11: '/workbench/myMachine' //机械租售审核
      },
      curNoticeType: "system",
      page: {
        page: 1,
        page_size: 4
      }
    }
  },
  created () {
    this.getData();
  },
  methods: {
    changeHandle (index) {
      this.noticeBar.forEach((item) => {
        item.active = false
      })
      this.noticeBar[index].active = true
      this.curNoticeType = this.noticeBar[index].value;
      this.page.page = 1;
      this.getData();
    },
    getData () {
      this.getList('getMsgList', this.page, { type: this.curNoticeType });
    }
  }

}
</script>
  
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.notices {
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  padding-bottom: 20px;
  .title {
    font-size: 22px;
    font-weight: 500;
    color: #2f2f2f;
    line-height: 26px;
    padding: 18px 25px 18px 9px;
    border-bottom: 1px solid #e5e5e5;
    margin: 0 25px 0 9px;
  }

  .notices_bar {
    margin: 30px 0 0 27px;
  }

  .panel {
    display: flex;
    flex-direction: column;
    gap: 12px 0;
    padding: 33px 22px 43px 28px;
    min-height: 560px;
    .panelItem {
      padding: 20px;
      background: #ffffff;
      box-shadow: 0px 0px 5px 0px #e4e9ff;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #e5e5e5;

      .item_head {
        padding: 0 2px;
        display: flex;
        justify-content: space-between;

        span {
          display: inline-block;

          &:first-child {
            font-size: 18px;
            font-weight: 500;
            color: #333333;
            line-height: 18px;
          }

          &:last-child {
            font-size: 13px;
            font-weight: 400;
            color: #999999;
            line-height: 16px;
          }
        }
      }

      .item_desc {
        padding: 11px 2px 15px;
        border-bottom: 1px solid #e5e5e5;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 16px;
      }

      .item_detail {
        font-size: 15px;
        font-weight: 400;
        color: #3f63ff;
        line-height: 18px;
        text-align: right;
        padding-top: 16px;
        cursor: pointer;
      }
    }
  }
}
</style>